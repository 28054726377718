var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.useFormFor === "mobile"
        ? _c("div", [
            _c(
              "div",
              { staticClass: "content-header" },
              [
                _c(
                  "b-row",
                  { staticClass: "mt-1" },
                  [
                    _c("b-col", { attrs: { cols: "9" } }, [
                      _c("div", [
                        _c(
                          "span",
                          {
                            staticClass: "tab-month",
                            class: {
                              "active-view-active": _vm.activeView === "month",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.changeActiveView("month")
                              },
                            },
                          },
                          [_c("b-icon", { attrs: { icon: "calendar4" } })],
                          1
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "tab-week",
                            class: {
                              "active-view-active": _vm.activeView === "day",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.changeActiveView("day")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(148, "Day")) +
                                "\n\t\t\t\t\t\t"
                            ),
                          ]
                        ),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showTabToday,
                                expression: "showTabToday",
                              },
                            ],
                            staticClass: "tab-today",
                            on: { click: _vm.handleClickToday },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(149, "Today")) +
                                "\n\t\t\t\t\t\t"
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "calendar mt-4" },
                  [
                    _c(
                      "b-row",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeView === "month",
                            expression: "activeView === 'month'",
                          },
                        ],
                      },
                      [
                        _c(
                          "b-col",
                          [
                            _c("Calendar", {
                              attrs: {
                                attributes: _vm.allowedEntryOutOfDateAttributes,
                                locale: _vm.getCurLangStr,
                                "from-page": _vm.dateToShow,
                                columns: 1,
                                "available-dates": _vm.allAvailableDates,
                                "is-expanded": "",
                              },
                              on: {
                                dayclick: _vm.calDateSelected,
                                "update:from-page": _vm.calPageUpdated,
                              },
                              model: {
                                value:
                                  _vm.allowedEntryOutOfDateAttributes[0].dates,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.allowedEntryOutOfDateAttributes[0],
                                    "dates",
                                    $$v
                                  )
                                },
                                expression:
                                  "allowedEntryOutOfDateAttributes[0].dates",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("calendar-work", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.activeView === "day",
                          expression: "activeView === 'day'",
                        },
                      ],
                      attrs: {
                        "show-status": !_vm.isFilmSingle,
                        "cur-day": _vm.curDay,
                      },
                      on: {
                        "calendar-work:change-date": _vm.calDateSelected,
                        "calendar-work:from-page": _vm.calPageUpdated,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "divider-line" }),
            _c(
              "div",
              { staticClass: "content-body" },
              [
                _c(
                  "b-collapse",
                  { attrs: { visible: !_vm.curDay.strEndNotSpecified } },
                  [
                    _c(
                      "b-collapse",
                      { attrs: { visible: !_vm.curDay.stageDayUsed } },
                      [
                        !_vm.curDay.strEndNotSpecified
                          ? _c(
                              "div",
                              [
                                !_vm.curDay.stageDayUsed
                                  ? _c(
                                      "b-row",
                                      [
                                        _c("b-col", [
                                          _c("h2", { staticClass: "fw-600" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.FormMSG(150, "Enter Time")
                                              )
                                            ),
                                          ]),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.disableSave
                          ? _c("div", { staticClass: "alert alert-warning" }, [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.FormMSG(
                                      11,
                                      "A timesheet has already been submitted for this day. Please choose another date."
                                    )
                                  ) +
                                  "\n\t\t\t\t\t"
                              ),
                            ])
                          : _vm._e(),
                        _vm.multipleSelectionDate === false &&
                        _vm.selectedDateFromMonth === true &&
                        _vm.allowTimesheetEntryOutOfContract === false &&
                        _vm.curDay.isAvailable === false &&
                        _vm.screenUsedTheForm === _vm.isTimeCard
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center alert alert-warning",
                                attrs: { role: "alert" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "text-center pr-1" },
                                  [
                                    _c(_vm.getLucideIcon("AlertTriangle"), {
                                      tag: "component",
                                      attrs: { color: "#CD9A16", size: 22 },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "ml-3",
                                    staticStyle: { "font-size": "1rem" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.FormMSG(
                                          701,
                                          `You don't have any contract valid on this selected date.`
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.multipleSelectionDate === false &&
                        _vm.allowTimesheetEntryOutOfContract === true &&
                        _vm.curDay.isAvailable === false &&
                        _vm.screenUsedTheForm === _vm.isTimeCard
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center alert alert-warning",
                                attrs: { role: "alert" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "text-center pr-1" },
                                  [
                                    _c(_vm.getLucideIcon("CheckCircle"), {
                                      tag: "component",
                                      attrs: { color: "#CD9A16", size: 22 },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "ml-2",
                                    staticStyle: { "font-size": "1rem" },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.FormMSG(
                                            702,
                                            `Even you don't have any contract valid on this selected date. The "app" let you enter your work details anyway.`
                                          )
                                        ) +
                                        "\n\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        !_vm.curDay.strEndNotSpecified
                          ? _c(
                              "div",
                              [
                                !_vm.curDay.stageDayUsed
                                  ? _c(
                                      "b-row",
                                      { staticClass: "mt-3" },
                                      [
                                        _c(
                                          "b-col",
                                          [
                                            _c("StartEndTimeOverRange", {
                                              attrs: {
                                                "start-backward-day": 1,
                                                "start-forward-day": 0,
                                                "end-backward-day": 0,
                                                "end-forward-day": 1,
                                                "hide-start-date": "",
                                                value: {
                                                  start: _vm.curDay.strTime,
                                                  end: _vm.curDay.endTime,
                                                  date: _vm.curDay.date,
                                                },
                                                "start-label": _vm.FormMSG(
                                                  209,
                                                  "Start Time"
                                                ),
                                                "end-label": _vm.FormMSG(
                                                  210,
                                                  "End Time"
                                                ),
                                                "disable-save": _vm.disableSave,
                                                "hide-label-date":
                                                  _vm.days.length > 1,
                                                days: _vm.days,
                                                multipleSelectionDate:
                                                  _vm.multipleSelectionDate,
                                              },
                                              on: {
                                                "change-range":
                                                  _vm.updateStrEndTimes,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        !_vm.curDay.strEndNotSpecified
                          ? _c(
                              "div",
                              [
                                !_vm.curDay.stageDayUsed
                                  ? _c(
                                      "b-row",
                                      { staticClass: "mt-3" },
                                      [
                                        _c("b-col", [
                                          _c("div", { staticClass: "total" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex flex-row justify-content-center align-items-center",
                                              },
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass:
                                                      "text-center p-0",
                                                    attrs: { cols: "4" },
                                                  },
                                                  [
                                                    _c(
                                                      "h2",
                                                      {
                                                        staticClass:
                                                          "total-hours fw-600",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.curDay.hhmm ===
                                                              ""
                                                              ? "--:--"
                                                              : _vm.getHhMmString(
                                                                  _vm.curDay
                                                                    .hhmm
                                                                )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "font-size": "14px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              202,
                                                              "Worked Hours"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm.canSeeSalary
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        staticClass:
                                                          "text-center p-0",
                                                        attrs: { cols: "4" },
                                                      },
                                                      [
                                                        _c(
                                                          "h2",
                                                          {
                                                            staticClass:
                                                              "total-hours fw-600",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.getCurrency(
                                                                  _vm.curDay
                                                                    .salary
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              "font-size":
                                                                "14px",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  221,
                                                                  "Salary"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass:
                                                      "text-center p-0",
                                                    attrs: { cols: "4" },
                                                  },
                                                  [
                                                    _c(
                                                      "h2",
                                                      {
                                                        staticClass:
                                                          "total-hours fw-600",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.curDay
                                                              .kgCoTwo === 0
                                                              ? "--"
                                                              : _vm.getCO2(
                                                                  _vm.curDay
                                                                    .kgCoTwo
                                                                )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "font-size": "14px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v("CO"),
                                                        _c("sub", [
                                                          _vm._v("2"),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "accordion",
                    staticStyle: { "margin-bottom": "40px" },
                    attrs: { role: "tablist" },
                  },
                  [
                    _vm.curDay.id !== 0
                      ? _c(
                          "b-row",
                          {
                            staticStyle: {
                              "margin-bottom": "24px",
                              "margin-top": "24px",
                            },
                          },
                          [
                            _c(
                              "b-col",
                              [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "b-toggle",
                                        rawName: "v-b-toggle.accordion-1",
                                        modifiers: { "accordion-1": true },
                                      },
                                    ],
                                    staticClass: "show-details d-flex flex-row",
                                    staticStyle: {
                                      color: "#225cbd",
                                      "font-size": "16px",
                                      "font-weight": "500",
                                      width: "100%",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleClickAccordion(
                                          "details"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticStyle: { width: "50%" } },
                                      [
                                        !_vm.isFilmSingle
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "wrap-status d-flex justify-content-start",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    class: `status ${_vm.curDay.statusClass}`,
                                                    staticStyle: {
                                                      "font-size": "0.815rem",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.curDay
                                                            .validatedStatus
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-end",
                                        staticStyle: { width: "50%" },
                                      },
                                      [
                                        _vm.accordionActive !== "details"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex flex-row justify-content-end align-items-center",
                                                staticStyle: { width: "100%" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "14px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          154,
                                                          "Show Details"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "px-1" },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ChevronDown"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 20 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex flex-row justify-content-end align-items-center",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "14px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          155,
                                                          "Hide Details"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "px-1" },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ChevronUp"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 20 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "b-collapse",
                                  {
                                    attrs: {
                                      id: "accordion-1",
                                      accordion: "my-accordion",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "content-detailsMyTimesheet-mobile",
                                      },
                                      [
                                        _c(
                                          "b-row",
                                          [
                                            _c("b-col", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "details-timesheet",
                                                },
                                                [
                                                  _c("ul", [
                                                    _c("li", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "column-1",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                510,
                                                                "Date"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "column-2",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.rendDate(
                                                                  _vm.curDay
                                                                    .date
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                    _c("li", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "column-1",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                520,
                                                                "Start"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "column-2",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.getHours(
                                                                _vm.curDay
                                                                  .strTime
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                    _c("li", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "column-1",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                530,
                                                                "End"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "column-2",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.getHours(
                                                                _vm.curDay
                                                                  .endTime
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                    _c("li", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "column-1",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                540,
                                                                "Type"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm.canSeeSalary
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "column-2",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.getFieldValueByShowGridDetails(
                                                                      true,
                                                                      _vm.curDay
                                                                        .dayType,
                                                                      _vm.curDay
                                                                        .salaryBase
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          )
                                                        : _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "column-2",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.getFieldValueByShowGridDetails(
                                                                      false,
                                                                      _vm.curDay
                                                                        .dayType
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                    ]),
                                                    _c("li", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "column-1",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                1690,
                                                                "Overtime"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm.canSeeSalary
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "column-2",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.curDay
                                                                      .hhmmOvt +
                                                                      " | " +
                                                                      _vm.getCurrency(
                                                                        _vm
                                                                          .curDay
                                                                          .salaryOvertime
                                                                      )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          )
                                                        : _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "column-2",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.curDay
                                                                      .hhmmOvt
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                    ]),
                                                    _c("li", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "column-1",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                1700,
                                                                "Overtime 1"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm.canSeeSalary
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "column-2",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.curDay
                                                                      .hhmmOvt1 +
                                                                      " | " +
                                                                      _vm.getCurrency(
                                                                        _vm
                                                                          .curDay
                                                                          .salaryOvertime1
                                                                      )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          )
                                                        : _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "column-2",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.curDay
                                                                      .hhmmOvt1
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                    ]),
                                                    _c("li", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "column-1",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                1710,
                                                                "Overtime 2"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm.canSeeSalary
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "column-2",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.curDay
                                                                      .hhmmOvt2 +
                                                                      " | " +
                                                                      _vm.getCurrency(
                                                                        _vm
                                                                          .curDay
                                                                          .salaryOvertime2
                                                                      )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          )
                                                        : _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "column-2",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.curDay
                                                                      .hhmmOvt2
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                    ]),
                                                    _c("li", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "column-1",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                1580,
                                                                "Transport"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm.canSeeSalary
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "column-2",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.curDay
                                                                      .hhmmTransport +
                                                                      " | " +
                                                                      _vm.getCurrency(
                                                                        _vm
                                                                          .curDay
                                                                          .salaryTransport
                                                                      )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          )
                                                        : _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "column-2",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.curDay
                                                                      .hhmmTransport
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                    ]),
                                                    _c("li", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "column-1",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                570,
                                                                "Night"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm.canSeeSalary
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "column-2",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.curDay
                                                                      .hhmmNight +
                                                                      " | " +
                                                                      _vm.getCurrency(
                                                                        _vm
                                                                          .curDay
                                                                          .salaryHourNight
                                                                      )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          )
                                                        : _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "column-2",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.curDay
                                                                      .hhmmNight
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                    ]),
                                                    _c("li", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "column-1",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                580,
                                                                "Anticipated"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm.canSeeSalary
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "column-2",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.curDay
                                                                      .hhmmTooEarly +
                                                                      " | " +
                                                                      _vm.getCurrency(
                                                                        _vm
                                                                          .curDay
                                                                          .salaryHourTooEarly
                                                                      )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          )
                                                        : _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "column-2",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.curDay
                                                                      .hhmmTooEarly
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                    ]),
                                                    _c("li", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "column-1",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                600,
                                                                "Lunch start"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "column-2",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.getHours(
                                                                _vm.curDay
                                                                  .lunchStrTime
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                    _c("li", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "column-1",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                650,
                                                                "Lunch end"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "column-2",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.getHours(
                                                                _vm.curDay
                                                                  .lunchEndTime
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                    (_vm.showLunchPerDiem() ||
                                                      _vm.showHotelPerDiem() ||
                                                      _vm.showDinnerPerDiem() ||
                                                      _vm.showAbroadPerDiem()) &&
                                                    _vm.curDay.totPerDiem > 0
                                                      ? _c("li", [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "column-1",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    2200,
                                                                    "Per Diem"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "column-2",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "d-flex justify-content-end align-items-center",
                                                                },
                                                                [
                                                                  _c(
                                                                    "button",
                                                                    {
                                                                      directives:
                                                                        [
                                                                          {
                                                                            name: "b-tooltip",
                                                                            rawName:
                                                                              "v-b-tooltip.hover.left.html",
                                                                            value:
                                                                              _vm.tooltipContentPerIdem(
                                                                                _vm.curDay
                                                                              ),
                                                                            expression:
                                                                              "tooltipContentPerIdem(curDay)",
                                                                            modifiers:
                                                                              {
                                                                                hover: true,
                                                                                left: true,
                                                                                html: true,
                                                                              },
                                                                          },
                                                                        ],
                                                                      staticClass:
                                                                        "btn-transparent text-color-rhapsody-in-blue clearfixx",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .INFO
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .INFO
                                                                                  .color,
                                                                              size: 16,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "margin-top":
                                                                            "4px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.getCurrency(
                                                                            _vm
                                                                              .curDay
                                                                              .totPerDiem
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    _vm.curDay.totAllowances > 0
                                                      ? _c("li", [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "column-1",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    2210,
                                                                    "Allowances"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "column-2",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "d-flex justify-content-end align-items-center",
                                                                },
                                                                [
                                                                  _c(
                                                                    "button",
                                                                    {
                                                                      directives:
                                                                        [
                                                                          {
                                                                            name: "b-tooltip",
                                                                            rawName:
                                                                              "v-b-tooltip.hover.left.html",
                                                                            value:
                                                                              _vm.tooltipContentAllowance(
                                                                                _vm.curDay
                                                                              ),
                                                                            expression:
                                                                              "tooltipContentAllowance(curDay)",
                                                                            modifiers:
                                                                              {
                                                                                hover: true,
                                                                                left: true,
                                                                                html: true,
                                                                              },
                                                                          },
                                                                        ],
                                                                      staticClass:
                                                                        "btn-transparent text-color-rhapsody-in-blue",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        _vm.getLucideIcon(
                                                                          "Info"
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .INFO
                                                                                  .color,
                                                                              size: 16,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "margin-top":
                                                                            "4px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.getCurrency(
                                                                            _vm
                                                                              .curDay
                                                                              .totAllowances
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    _c("li", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "column-1",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                590,
                                                                "Rate"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "column-2",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.curDay
                                                                .dayRate + "%"
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                  ]),
                                                ]
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.curDay.id !== 0
                      ? _c("div", { staticClass: "divider-line" })
                      : _vm._e(),
                    _c(
                      "b-row",
                      {
                        staticStyle: {
                          "margin-bottom": "24px",
                          "margin-top": "24px",
                        },
                      },
                      [
                        _c(
                          "b-col",
                          [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName: "v-b-toggle.accordion-type",
                                    modifiers: { "accordion-type": true },
                                  },
                                ],
                                staticClass: "accordion-details",
                                staticStyle: {
                                  width: "100%",
                                  "text-align": "left",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleClickAccordion("type")
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "90%",
                                      display: "flex",
                                    },
                                  },
                                  [
                                    _c("div", [
                                      _c("h2", { staticClass: "fw-600" }, [
                                        _vm._v(
                                          _vm._s(_vm.FormMSG(180, "Type"))
                                        ),
                                      ]),
                                    ]),
                                    _c("div", [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "status next-day",
                                          staticStyle: {
                                            "margin-left": "5px",
                                            display: "inline-block",
                                            "text-transform": "uppercase",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.getDayType(
                                                  _vm.curDay.dayType
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "10%",
                                      "padding-top": "10px",
                                    },
                                  },
                                  [
                                    _vm.accordionActive !== "type"
                                      ? _c("b-icon", {
                                          staticClass: "float-right",
                                          attrs: { icon: "chevron-down" },
                                        })
                                      : _c("b-icon", {
                                          staticClass: "float-right",
                                          attrs: { icon: "chevron-up" },
                                        }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "b-collapse",
                              {
                                staticClass: "mt-2",
                                attrs: {
                                  id: "accordion-type",
                                  accordion: "my-accordion",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _c("b-form-select", {
                                      attrs: {
                                        options: _vm.optionsForDefaultDayType,
                                        size: "md",
                                      },
                                      model: {
                                        value: _vm.curDay.dayType,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.curDay, "dayType", $$v)
                                        },
                                        expression: "curDay.dayType",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                !_vm.curDay.stageDayUsed
                                  ? _c(
                                      "div",
                                      { staticClass: "mt-4" },
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            staticClass: "pj-cb pb-1",
                                            attrs: {
                                              id: "hours_not_specified",
                                              size: "lg",
                                              disabled: _vm.disableSave,
                                            },
                                            model: {
                                              value:
                                                _vm.curDay.strEndNotSpecified,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.curDay,
                                                  "strEndNotSpecified",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "curDay.strEndNotSpecified",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    124,
                                                    "Hours not specified"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                !_vm.curDay.strEndNotSpecified
                                  ? _c("div", [
                                      _vm.showStageDay()
                                        ? _c(
                                            "div",
                                            { staticClass: "mt-4" },
                                            [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  staticClass: "pj-cb pb-1",
                                                  attrs: {
                                                    id: "stageDayUsed",
                                                    size: "lg",
                                                    disabled:
                                                      _vm.disableSave ||
                                                      !_vm.hasAvailableStageDay,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.curDay.stageDayUsed,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.curDay,
                                                        "stageDayUsed",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "curDay.stageDayUsed",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          24,
                                                          "Use Stage Day"
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "divider-line" }),
                    _c(
                      "b-row",
                      {
                        staticStyle: {
                          "margin-bottom": "24px",
                          "margin-top": "24px",
                        },
                      },
                      [
                        _c(
                          "b-col",
                          [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName: "v-b-toggle.accordion-lunch",
                                    modifiers: { "accordion-lunch": true },
                                  },
                                ],
                                staticClass: "accordion-details",
                                staticStyle: {
                                  width: "100%",
                                  "text-align": "left",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleClickAccordion("lunch")
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "90%",
                                      display: "flex",
                                    },
                                  },
                                  [
                                    _c("div", [
                                      _c("h2", { staticClass: "fw-600" }, [
                                        _vm._v(
                                          _vm._s(_vm.FormMSG(181, "Lunch"))
                                        ),
                                      ]),
                                    ]),
                                    _c("div", [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "status next-day",
                                          staticStyle: {
                                            "margin-left": "5px",
                                            display: "inline-block",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.getLunchPerDiemCurDayString()
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "10%",
                                      "padding-top": "10px",
                                    },
                                  },
                                  [
                                    _vm.accordionActive !== "lunch"
                                      ? _c("b-icon", {
                                          staticClass: "float-right",
                                          attrs: { icon: "chevron-down" },
                                        })
                                      : _c("b-icon", {
                                          staticClass: "float-right",
                                          attrs: { icon: "chevron-up" },
                                        }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "b-collapse",
                              {
                                staticClass: "mt-2",
                                attrs: {
                                  id: "accordion-lunch",
                                  accordion: "my-accordion",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "w-100" },
                                  [
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        staticClass: "pj-cb pb-1",
                                        attrs: {
                                          id: "lunchNotSpec",
                                          size: "lg",
                                          disabled: _vm.disableSave,
                                        },
                                        model: {
                                          value: _vm.curDay.notSpecifiedLunch,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.curDay,
                                              "notSpecifiedLunch",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "curDay.notSpecifiedLunch",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(280, "Not specified")
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                    !_vm.curDay.stageDayUsed &&
                                    _vm.showLunch() &&
                                    !_vm.curDay.notSpecifiedLunch
                                      ? _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "total" },
                                                  [
                                                    _c(
                                                      "b-row",
                                                      [
                                                        _c(
                                                          "b-col",
                                                          [
                                                            _c(
                                                              "StartEndTimeOverRange",
                                                              {
                                                                attrs: {
                                                                  "start-backward-day": 1,
                                                                  "start-forward-day": 1,
                                                                  "end-backward-day": 1,
                                                                  "end-forward-day": 2,
                                                                  value: {
                                                                    start:
                                                                      _vm.curDay
                                                                        .lunchStrTime,
                                                                    end: _vm
                                                                      .curDay
                                                                      .lunchEndTime,
                                                                    date: _vm
                                                                      .curDay
                                                                      .date,
                                                                  },
                                                                  "disable-save":
                                                                    _vm.disableSave,
                                                                  "hide-label-date":
                                                                    _vm.days
                                                                      .length >
                                                                    1,
                                                                  "start-label":
                                                                    _vm.FormMSG(
                                                                      29,
                                                                      "Lunch Start:"
                                                                    ),
                                                                  "end-label":
                                                                    _vm.FormMSG(
                                                                      30,
                                                                      "Lunch End:"
                                                                    ),
                                                                  days: _vm.days,
                                                                  multipleSelectionDate:
                                                                    _vm.multipleSelectionDate,
                                                                },
                                                                on: {
                                                                  "change-range":
                                                                    _vm.updateLunchTimes,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "b-row",
                                      { staticClass: "mt-2" },
                                      [
                                        _vm.curDay.lunch
                                          ? _c(
                                              "b-col",
                                              {
                                                staticClass: "text-center p-0",
                                                attrs: { cols: "12" },
                                              },
                                              [
                                                _c(
                                                  "h2",
                                                  {
                                                    staticClass: "total-hours",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getCurrency(
                                                          !_vm.curDay
                                                            .lunchPerDiem
                                                            ? 0
                                                            : _vm.curDay
                                                                .lunchPerDiem
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c("p", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        220,
                                                        "Total Amount"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "divider-line" }),
                    _c(
                      "b-row",
                      {
                        staticStyle: {
                          "margin-bottom": "24px",
                          "margin-top": "24px",
                        },
                      },
                      [
                        _c(
                          "b-col",
                          [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName: "v-b-toggle.accordion-perDiem",
                                    modifiers: { "accordion-perDiem": true },
                                  },
                                ],
                                staticClass: "accordion-details",
                                staticStyle: {
                                  width: "100%",
                                  "text-align": "left",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleClickAccordion("hotel")
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "90%",
                                      display: "flex",
                                    },
                                  },
                                  [
                                    _c("div", [
                                      _c("h2", [
                                        _vm._v(
                                          _vm._s(_vm.FormMSG(680, "Per Diem"))
                                        ),
                                      ]),
                                    ]),
                                    _vm.curDay.hotel ||
                                    _vm.curDay.useAbroad ||
                                    _vm.curDay.lunch ||
                                    _vm.curDay.useDinner
                                      ? _c("div", [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "status next-day",
                                              staticStyle: {
                                                "margin-left": "5px",
                                                display: "inline-block",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(310, "Yes")
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "10%",
                                      "padding-top": "10px",
                                    },
                                  },
                                  [
                                    _vm.accordionActive !== "hotel"
                                      ? _c("b-icon", {
                                          staticClass: "float-right",
                                          attrs: { icon: "chevron-down" },
                                        })
                                      : _c("b-icon", {
                                          staticClass: "float-right",
                                          attrs: { icon: "chevron-up" },
                                        }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "b-collapse",
                              {
                                staticClass: "mt-2",
                                attrs: {
                                  id: "accordion-perDiem",
                                  accordion: "my-accordion",
                                },
                              },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _vm.showLunchPerDiem()
                                      ? _c(
                                          "b-col",
                                          { staticClass: "lh-20" },
                                          [
                                            _c(
                                              "b-form-checkbox",
                                              {
                                                staticClass: "pj-cb pb-1",
                                                attrs: {
                                                  id: "lunch",
                                                  size: "lg",
                                                  disabled: _vm.disableSave,
                                                },
                                                model: {
                                                  value: _vm.curDay.lunch,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.curDay,
                                                      "lunch",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "curDay.lunch",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        28,
                                                        "Lunch Per Diem"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.showDinnerPerDiem()
                                      ? _c(
                                          "b-col",
                                          [
                                            _c(
                                              "b-form-checkbox",
                                              {
                                                staticClass: "pj-cb pb-1",
                                                attrs: {
                                                  id: "dinner",
                                                  size: "lg",
                                                  disabled: _vm.disableSave,
                                                },
                                                model: {
                                                  value: _vm.curDay.useDinner,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.curDay,
                                                      "useDinner",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "curDay.useDinner",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        281,
                                                        "Dinner Per Diem"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _vm.showHotelPerDiem()
                                      ? _c(
                                          "b-col",
                                          [
                                            _c(
                                              "b-form-checkbox",
                                              {
                                                staticClass: "pj-cb pb-1",
                                                attrs: {
                                                  id: "perDiem",
                                                  size: "lg",
                                                  disabled: _vm.disableSave,
                                                },
                                                model: {
                                                  value: _vm.curDay.hotel,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.curDay,
                                                      "hotel",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "curDay.hotel",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        31,
                                                        "Hotel Per Diem"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.showAbroadPerDiem()
                                      ? _c(
                                          "b-col",
                                          [
                                            _c(
                                              "b-form-checkbox",
                                              {
                                                staticClass: "pj-cb pb-1",
                                                attrs: {
                                                  id: "abroad",
                                                  size: "lg",
                                                  disabled: _vm.disableSave,
                                                },
                                                model: {
                                                  value: _vm.curDay.useAbroad,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.curDay,
                                                      "useAbroad",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "curDay.useAbroad",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        282,
                                                        "Abroad Per Diem"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "divider-line" }),
                    _vm.showTransport()
                      ? _c(
                          "b-row",
                          {
                            staticStyle: {
                              "margin-top": "24px",
                              "margin-bottom": "24px",
                            },
                          },
                          [
                            _c(
                              "b-col",
                              [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "b-toggle",
                                        rawName:
                                          "v-b-toggle.accordion-transport",
                                        modifiers: {
                                          "accordion-transport": true,
                                        },
                                      },
                                    ],
                                    staticClass: "accordion-details",
                                    staticStyle: {
                                      width: "100%",
                                      "text-align": "left",
                                      cursor: "pointer",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleClickAccordion(
                                          "transport"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          width: "90%",
                                          display: "flex",
                                        },
                                      },
                                      [
                                        _c("div", [
                                          _c("h2", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.FormMSG(
                                                  139,
                                                  "Transport from/to home"
                                                )
                                              )
                                            ),
                                          ]),
                                        ]),
                                        _vm.curDay.hhmmTransport !== "0:00" &&
                                        _vm.curDay.hhmmTransport !== ""
                                          ? _c("div", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "status next-day",
                                                  staticStyle: {
                                                    "margin-left": "5px",
                                                    display: "inline-block",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.getHhMmString(
                                                          _vm.curDay
                                                            .hhmmTransport
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          width: "10%",
                                          "padding-top": "10px",
                                        },
                                      },
                                      [
                                        _vm.accordionActive !== "transport"
                                          ? _c("b-icon", {
                                              staticClass: "float-right",
                                              attrs: { icon: "chevron-down" },
                                            })
                                          : _c("b-icon", {
                                              staticClass: "float-right",
                                              attrs: { icon: "chevron-up" },
                                            }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "pt-3 pb-2 lh-20" },
                                  [
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        staticClass: "pj-cb pb-1",
                                        attrs: {
                                          id: "specifyTransport",
                                          size: "lg",
                                          disabled: _vm.disableSave,
                                        },
                                        model: {
                                          value:
                                            _vm.curDay.transportFromHomeToWork,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.curDay,
                                              "transportFromHomeToWork",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "curDay.transportFromHomeToWork",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(
                                                224,
                                                "Specify transport time from home to work"
                                              )
                                            ) +
                                            "\n\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-collapse",
                                  {
                                    staticClass: "mt-2",
                                    attrs: {
                                      visible:
                                        _vm.curDay.transportFromHomeToWork,
                                    },
                                  },
                                  [
                                    _c("div", [
                                      _c(
                                        "div",
                                        [
                                          _c("StartEndTimeOverRange", {
                                            attrs: {
                                              "start-backward-day": 1,
                                              "start-forward-day": 0,
                                              "end-backward-day": 0,
                                              "end-forward-day": 2,
                                              "hide-label-date":
                                                _vm.days.length > 1,
                                              value: {
                                                start: _vm.curDay.leftHomeAt,
                                                end: _vm.curDay.returnedHomeAt,
                                                date: _vm.curDay.date,
                                              },
                                              "disable-save": _vm.disableSave,
                                              "start-label": _vm.FormMSG(
                                                126,
                                                "Left home at:"
                                              ),
                                              "end-label": _vm.FormMSG(
                                                127,
                                                "Returned home at:"
                                              ),
                                              days: _vm.days,
                                              multipleSelectionDate:
                                                _vm.multipleSelectionDate,
                                            },
                                            on: {
                                              "change-range":
                                                _vm.updateLeftReturnedTimes,
                                            },
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "total mt-3" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row justify-content-center align-items-center",
                                                },
                                                [
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "text-center p-0",
                                                      attrs: { cols: "6" },
                                                    },
                                                    [
                                                      _c(
                                                        "h2",
                                                        {
                                                          staticClass:
                                                            "total-hours",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.curDay
                                                                  .hhmmTransport ===
                                                                  ""
                                                                  ? "--:--"
                                                                  : _vm.getHhMmString(
                                                                      _vm.curDay
                                                                        .hhmmTransport
                                                                    )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            "font-size": "14px",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                201,
                                                                "Transport Hours"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm.canSeeSalary
                                                    ? _c(
                                                        "b-col",
                                                        {
                                                          staticClass:
                                                            "text-center p-0",
                                                          attrs: { cols: "6" },
                                                        },
                                                        [
                                                          _c(
                                                            "h2",
                                                            {
                                                              staticClass:
                                                                "total-amount",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.getCurrency(
                                                                      !_vm
                                                                        .curDay
                                                                        .salaryTransport
                                                                        ? 0
                                                                        : _vm
                                                                            .curDay
                                                                            .salaryTransport
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                "font-size":
                                                                  "14px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    205,
                                                                    "Transport Amount"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "divider-line" }),
                    _c(
                      "b-row",
                      {
                        staticStyle: {
                          "margin-top": "24px",
                          "margin-bottom": "24px",
                        },
                      },
                      [
                        _c(
                          "b-col",
                          [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName: "v-b-toggle.working_site",
                                    modifiers: { working_site: true },
                                  },
                                ],
                                staticClass: "accordion-details",
                                staticStyle: {
                                  width: "100%",
                                  "text-align": "left",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleClickAccordion(
                                      "working_site"
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "90%",
                                      display: "flex",
                                    },
                                  },
                                  [
                                    _c("div", [
                                      _c("h2", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.FormMSG(698, "Working site")
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "10%",
                                      "padding-top": "10px",
                                    },
                                  },
                                  [
                                    _vm.accordionActive !== "working_site"
                                      ? _c("b-icon", {
                                          staticClass: "float-right",
                                          attrs: { icon: "chevron-down" },
                                        })
                                      : _c("b-icon", {
                                          staticClass: "float-right",
                                          attrs: { icon: "chevron-up" },
                                        }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "b-collapse",
                              {
                                staticClass: "mt-2",
                                attrs: {
                                  id: "working_site",
                                  accordion: "my-accordion",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          { attrs: { cols: "6" } },
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  label: _vm.FormMSG(
                                                    587,
                                                    "Working site"
                                                  ),
                                                },
                                              },
                                              [
                                                _c("b-form-select", {
                                                  attrs: {
                                                    label: "setName",
                                                    "text-field": "setName",
                                                    "value-field": "id",
                                                    options:
                                                      _vm.projectLocationsList,
                                                    placeholder: _vm.FormMSG(
                                                      594,
                                                      "Please select"
                                                    ),
                                                    reduce: (opt) => opt.id,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "first",
                                                        fn: function () {
                                                          return [
                                                            _c(
                                                              "b-form-select-option",
                                                              {
                                                                attrs: {
                                                                  value: null,
                                                                  disabled: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      594,
                                                                      "Please select"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    4027304841
                                                  ),
                                                  model: {
                                                    value:
                                                      _vm.curDay.locationId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.curDay,
                                                        "locationId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "curDay.locationId",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-col",
                                          { attrs: { cols: "6" } },
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  label: _vm.FormMSG(
                                                    587,
                                                    "Working site"
                                                  ),
                                                },
                                              },
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    placeholder: _vm.FormMSG(
                                                      588,
                                                      "Enter name of site"
                                                    ),
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "divider-line" }),
                    _c(
                      "b-row",
                      {
                        staticStyle: {
                          "margin-top": "24px",
                          "margin-bottom": "24px",
                        },
                      },
                      [
                        _c(
                          "b-col",
                          [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName: "v-b-toggle.allowance",
                                    modifiers: { allowance: true },
                                  },
                                ],
                                staticClass: "accordion-details",
                                staticStyle: {
                                  width: "100%",
                                  "text-align": "left",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleClickAccordion("allowance")
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "90%",
                                      display: "flex",
                                    },
                                  },
                                  [
                                    _c("div", [
                                      _c("h2", [
                                        _vm._v(
                                          _vm._s(_vm.FormMSG(289, "Allowance"))
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "10%",
                                      "padding-top": "10px",
                                    },
                                  },
                                  [
                                    _vm.accordionActive !== "allowance"
                                      ? _c("b-icon", {
                                          staticClass: "float-right",
                                          attrs: { icon: "chevron-down" },
                                        })
                                      : _c("b-icon", {
                                          staticClass: "float-right",
                                          attrs: { icon: "chevron-up" },
                                        }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "b-collapse",
                              {
                                staticClass: "mt-2",
                                attrs: {
                                  id: "allowance",
                                  accordion: "my-accordion",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        staticClass: "pj-cb pb-1",
                                        attrs: {
                                          id: "chkMileageToSet",
                                          size: "lg",
                                        },
                                        model: {
                                          value: _vm.curDay.useMileageToSet,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.curDay,
                                              "useMileageToSet",
                                              $$v
                                            )
                                          },
                                          expression: "curDay.useMileageToSet",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(265, "Mileage to set")
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "divider-line" }),
                    _c(
                      "b-row",
                      {
                        staticStyle: {
                          "margin-top": "24px",
                          "margin-bottom": "24px",
                        },
                      },
                      [
                        _c(
                          "b-col",
                          [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName: "v-b-toggle.commentSection",
                                    modifiers: { commentSection: true },
                                  },
                                ],
                                staticClass: "accordion-details",
                                staticStyle: {
                                  width: "100%",
                                  "text-align": "left",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleClickAccordion("comment")
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "90%",
                                      display: "flex",
                                    },
                                  },
                                  [
                                    _c("div", [
                                      _c("h2", [
                                        _vm._v(
                                          _vm._s(_vm.FormMSG(26540, "Comment"))
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "10%",
                                      "padding-top": "10px",
                                    },
                                  },
                                  [
                                    _vm.accordionActive !== "comment"
                                      ? _c("b-icon", {
                                          staticClass: "float-right",
                                          attrs: { icon: "chevron-down" },
                                        })
                                      : _c("b-icon", {
                                          staticClass: "float-right",
                                          attrs: { icon: "chevron-up" },
                                        }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "b-collapse",
                              {
                                staticClass: "mt-2",
                                attrs: {
                                  id: "commentSection",
                                  accordion: "my-accordion",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _c("b-form-textarea", {
                                      attrs: {
                                        disabled: _vm.disableSave,
                                        rows: "3",
                                      },
                                      model: {
                                        value: _vm.curDay.remark,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.curDay, "remark", $$v)
                                        },
                                        expression: "curDay.remark",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "divider-line" }),
                  ],
                  1
                ),
                (_vm.curDay.isAvailable &&
                  !_vm.allowTimesheetEntryOutOfContract) ||
                _vm.allowTimesheetEntryOutOfContract
                  ? _c(
                      "div",
                      [
                        !(
                          _vm.disableSaveButton ||
                          _vm.disableSave ||
                          _vm.dateDisabled() === true
                        )
                          ? _c(
                              "b-row",
                              { staticClass: "footer-fixed" },
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticStyle: {
                                          "font-size": "16px",
                                          "font-weight": "700",
                                        },
                                        attrs: {
                                          variant: "primary",
                                          disabled:
                                            _vm.disableSaveButton ||
                                            _vm.disableSave ||
                                            _vm.dateDisabled() === true,
                                          block: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.saveCurDay()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t" +
                                            _vm._s(_vm.buttonLabel()) +
                                            "\n\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.useFormFor === "web"
        ? _c(
            "div",
            [
              _c(
                "b-card",
                {
                  staticClass: "card-border-blue-light",
                  attrs: { "no-body": "" },
                },
                [
                  !_vm.multipleSelectionDate && !_vm.useViewPlanning
                    ? _c(
                        "b-row",
                        {
                          staticClass:
                            "back-with-title cursor-pointer d-flex align-items-center py-1",
                          class: { "mt-16": _vm.$isPwa() },
                          on: { click: _vm.handleGoBack },
                        },
                        [
                          _c(
                            "b-col",
                            {
                              staticClass: "d-flex align-items-center pl-0",
                              attrs: { cols: "8" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "icon" },
                                [
                                  _c(_vm.getLucideIcon("ArrowLeft"), {
                                    tag: "component",
                                    attrs: {
                                      color: "rgba(6, 38, 62, 0.65)",
                                      size: 22,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "ml-1",
                                  staticStyle: { "margin-top": "4px" },
                                },
                                [
                                  _c("h2", [
                                    _vm._v(_vm._s(_vm.FormMSG(703, "Back"))),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-card-body",
                    [
                      _c(
                        "fieldset",
                        {
                          staticClass:
                            "scheduler-border border-color-blue-streak",
                        },
                        [
                          _c(
                            "legend",
                            {
                              staticClass:
                                "scheduler-border text-color-blue-streak fs-16",
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(156, "Dates")) +
                                  "\n\t\t\t\t\t"
                              ),
                            ]
                          ),
                          !_vm.useViewPlanning
                            ? _c(
                                "div",
                                [
                                  !_vm.hideAlert
                                    ? _c("b-col", { attrs: { cols: "12" } }, [
                                        _c(
                                          "div",
                                          { staticClass: "alert alert-info" },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t" +
                                                _vm._s(_vm.caption2) +
                                                "\n\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                        _vm.disableSave
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "alert alert-warning",
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        11,
                                                        "A timesheet has already been submitted for this day. Please choose another date."
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.multipleSelectionDate === false &&
                                        _vm.selectedDateFromMonth === true &&
                                        _vm.allowTimesheetEntryOutOfContract ===
                                          false &&
                                        _vm.curDay.isAvailable === false &&
                                        _vm.screenUsedTheForm === _vm.isTimeCard
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-items-center alert alert-warning",
                                                attrs: { role: "alert" },
                                              },
                                              [
                                                _c(
                                                  _vm.getLucideIcon(
                                                    "AlertTriangle"
                                                  ),
                                                  {
                                                    tag: "component",
                                                    attrs: {
                                                      color: "#CD9A16",
                                                      size: 22,
                                                    },
                                                  }
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "ml-2",
                                                    staticStyle: {
                                                      "font-size": "1rem",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            701,
                                                            `You don't have any contract valid on this selected date.`
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.screenUsedTheForm ===
                                          _vm.isTimeCard &&
                                        _vm.multipleSelectionDate === false &&
                                        _vm.curDay.isAvailable === false &&
                                        _vm.allowTimesheetEntryOutOfContract ===
                                          true
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-items-center alert alert-warning",
                                                attrs: { role: "alert" },
                                              },
                                              [
                                                _c(
                                                  _vm.getLucideIcon(
                                                    "CheckCircle"
                                                  ),
                                                  {
                                                    tag: "component",
                                                    attrs: {
                                                      color: "#CD9A16",
                                                      size: 22,
                                                    },
                                                  }
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "ml-2",
                                                    staticStyle: {
                                                      "font-size": "1rem",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            702,
                                                            `Even you don't have any contract valid on this selected date. The "app" let you enter your work details anyway.`
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _vm.useFormInModal && _vm.timeCardToEdit
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "b-row",
                                            { staticClass: "pt-1 pb-4" },
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c(
                                                    "table",
                                                    { staticClass: "location" },
                                                    [
                                                      _c("thead", [
                                                        _c("tr", [
                                                          _c(
                                                            "th",
                                                            {
                                                              staticClass:
                                                                "p-0 pl-2",
                                                              staticStyle: {
                                                                width: "35%",
                                                              },
                                                              attrs: {
                                                                scope: "col",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    535,
                                                                    "Name"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "th",
                                                            {
                                                              staticClass:
                                                                "p-0 pl-2",
                                                              staticStyle: {
                                                                width: "55%",
                                                              },
                                                              attrs: {
                                                                scope: "col",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    536,
                                                                    "Dates"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]),
                                                      ]),
                                                      _c(
                                                        "tbody",
                                                        [
                                                          _vm._l(
                                                            _vm.listUserWithDates,
                                                            function (list) {
                                                              return _vm._l(
                                                                list.dates,
                                                                function (
                                                                  date,
                                                                  index2
                                                                ) {
                                                                  return _c(
                                                                    "tr",
                                                                    {
                                                                      key: index2,
                                                                      staticClass:
                                                                        "dynamic_address_location_container",
                                                                    },
                                                                    [
                                                                      index2 ===
                                                                      0
                                                                        ? _c(
                                                                            "td",
                                                                            {
                                                                              staticClass:
                                                                                "p-0 pl-2",
                                                                              attrs:
                                                                                {
                                                                                  rowspan:
                                                                                    list
                                                                                      .dates
                                                                                      .length,
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "fs-14 fw-700",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      list.fullName
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _c(
                                                                        "td",
                                                                        {
                                                                          staticClass:
                                                                            "p-0 pl-2",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "fs-14",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.formatedDate(
                                                                                    date
                                                                                  )
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              )
                                                            }
                                                          ),
                                                        ],
                                                        2
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        [
                                          _c(
                                            "b-row",
                                            {
                                              staticClass:
                                                "d-flex justify-content-start",
                                            },
                                            [
                                              _c(
                                                "b-col",
                                                {
                                                  staticClass: "pt-0",
                                                  attrs: { sm: "6" },
                                                },
                                                [
                                                  !_vm.hideStatus
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-sm-12 col-md-12 col-lg-12 col-xl-12",
                                                        },
                                                        [
                                                          !_vm.isFilmSingle
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "wrap-status justify-content-start pb-1",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      class: `status ${_vm.curDay.statusClass}`,
                                                                      staticStyle:
                                                                        {
                                                                          "font-size":
                                                                            "0.925rem",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                          _vm._s(
                                                                            _vm
                                                                              .curDay
                                                                              .validatedStatus
                                                                          ) +
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _vm.multipleSelectionDate
                                                ? _c(
                                                    "b-col",
                                                    { attrs: { sm: "6" } },
                                                    [
                                                      _vm.days.length === 0
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "fs-16 fw-700",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    589,
                                                                    "You must choice dates"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        : _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "fs-16 fw-700",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    547,
                                                                    "Selected dates"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                  _vm.screenUsedTheForm === _vm.isTimeCard ||
                                  (_vm.screenUsedTheForm ===
                                    _vm.isEntryForCrew &&
                                    _vm.timeCardToEdit === null)
                                    ? _c(
                                        "b-row",
                                        { staticClass: "pl-3" },
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              staticClass: "pl-0",
                                              attrs: { sm: "6" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "ml-3 pb-4" },
                                                [
                                                  _vm.multipleSelectionDate
                                                    ? _c("Calendar", {
                                                        attrs: {
                                                          attributes:
                                                            _vm.attributes,
                                                          "is-expanded": "",
                                                          locale:
                                                            _vm.getCurLangStr,
                                                          "max-date":
                                                            new Date(),
                                                        },
                                                        on: {
                                                          dayclick:
                                                            _vm.onDayClick,
                                                          "update:from-page":
                                                            _vm.calPageUpdated,
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  !_vm.multipleSelectionDate
                                                    ? _c("Calendar", {
                                                        attrs: {
                                                          attributes:
                                                            _vm.allowedEntryOutOfDateAttributes,
                                                          "from-page":
                                                            _vm.dateToShow,
                                                          locale:
                                                            _vm.getCurLangStr,
                                                          columns: 1,
                                                          "available-dates":
                                                            _vm.allAvailableDates,
                                                          "is-expanded": "",
                                                        },
                                                        on: {
                                                          dayclick:
                                                            _vm.calDateSelected,
                                                          "update:from-page":
                                                            _vm.calPageUpdated,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .allowedEntryOutOfDateAttributes[0]
                                                              .dates,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm
                                                                .allowedEntryOutOfDateAttributes[0],
                                                              "dates",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "allowedEntryOutOfDateAttributes[0].dates",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm.multipleSelectionDate
                                            ? _c(
                                                "b-col",
                                                {
                                                  staticClass:
                                                    "pr-2 list-selected-dates-form-time-card",
                                                  attrs: { sm: "6" },
                                                },
                                                [
                                                  _vm.days.length > 0
                                                    ? _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            "perfect-scrollbar",
                                                            {
                                                              staticStyle: {
                                                                height: "255px",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.days,
                                                              function (
                                                                day,
                                                                index
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    key: index,
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "fs-14",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm._f(
                                                                              "formatDate"
                                                                            )(
                                                                              day.id,
                                                                              "dddd MMMM DD, YYYY"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                { staticClass: "pb-4" },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c("b-col", { attrs: { cols: "12" } }, [
                                        _c(
                                          "table",
                                          { staticClass: "location" },
                                          [
                                            _c("thead", [
                                              _c("tr", [
                                                _c(
                                                  "th",
                                                  {
                                                    staticClass: "p-0 pl-2",
                                                    staticStyle: {
                                                      width: "35%",
                                                    },
                                                    attrs: { scope: "col" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.FormMSG(535, "Name")
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "th",
                                                  {
                                                    staticClass: "p-0 pl-2",
                                                    staticStyle: {
                                                      width: "55%",
                                                    },
                                                    attrs: { scope: "col" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          536,
                                                          "Dates"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c("th", {
                                                  staticClass: "p-0 pl-2",
                                                  staticStyle: { width: "10%" },
                                                  attrs: { scope: "col" },
                                                }),
                                              ]),
                                            ]),
                                            _c(
                                              "tbody",
                                              [
                                                _vm._l(
                                                  _vm.listUserWithDates,
                                                  function (list, index) {
                                                    return _vm._l(
                                                      list.dates,
                                                      function (date, index2) {
                                                        return _c(
                                                          "tr",
                                                          {
                                                            key: `${
                                                              index2 +
                                                              Math.random() *
                                                                1000000
                                                            }`,
                                                            staticClass:
                                                              "dynamic_address_location_container",
                                                          },
                                                          [
                                                            index2 === 0
                                                              ? _c(
                                                                  "td",
                                                                  {
                                                                    staticClass:
                                                                      "p-0 pl-2",
                                                                    attrs: {
                                                                      rowspan:
                                                                        list
                                                                          .dates
                                                                          .length,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "fs-14 fw-700",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            list.fullName
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "p-0 pl-2",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "fs-14",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.formatedDate(
                                                                          date
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "text-danger text-center p-0",
                                                              },
                                                              [
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn-transparent text-danger",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handleClickDeleteDate(
                                                                            index,
                                                                            date
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      _vm.getLucideIcon(
                                                                        "Trash"
                                                                      ),
                                                                      {
                                                                        tag: "component",
                                                                        attrs: {
                                                                          size: 16,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                        ]
                      ),
                      !_vm.useViewPlanning && _vm.timeCardToEdit === null
                        ? _c("div", [
                            !_vm.hidePersonSelector ||
                            !_vm.hideDepartmentSelector
                              ? _c(
                                  "fieldset",
                                  {
                                    staticClass:
                                      "scheduler-border border-color-blue-streak",
                                  },
                                  [
                                    _c(
                                      "legend",
                                      {
                                        staticClass:
                                          "scheduler-border text-color-blue-streak fs-16",
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(157, "Persons")
                                            ) +
                                            "\n\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "b-row",
                                      [
                                        !_vm.hidePersonSelector
                                          ? _c(
                                              "b-col",
                                              { attrs: { md: "6" } },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        2,
                                                        "Add a person"
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-input-group",
                                                      { staticClass: "mb-3" },
                                                      [
                                                        _c("v-select", {
                                                          staticClass: "w-100",
                                                          attrs: {
                                                            disabled:
                                                              _vm.disableSave ||
                                                              _vm.disableUserSelect,
                                                            value:
                                                              _vm.singleUserSelected,
                                                            placeholder:
                                                              _vm.FormMSG(
                                                                6,
                                                                "Select a person"
                                                              ),
                                                            options:
                                                              _vm.userList,
                                                            label: "message",
                                                            reduce: (option) =>
                                                              option.value,
                                                          },
                                                          on: {
                                                            input:
                                                              _vm.handleInputSelectUser,
                                                            "option:selected":
                                                              _vm.handleSelectUser,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.hidePersonSelector
                                          ? _c("div", { staticClass: "mt-5" }, [
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(
                                                    this.FormMSG(564, "OR")
                                                  )
                                                ),
                                              ]),
                                            ])
                                          : _vm._e(),
                                        !_vm.hideDepartmentSelector
                                          ? _c(
                                              "b-col",
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        3,
                                                        "Add a department"
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-input-group",
                                                      { staticClass: "mb-3" },
                                                      [
                                                        _c("v-select", {
                                                          staticClass: "w-100",
                                                          attrs: {
                                                            disabled:
                                                              _vm.disableSave ||
                                                              _vm.disableUserSelect,
                                                            placeholder:
                                                              _vm.FormMSG(
                                                                6,
                                                                "Select a person"
                                                              ),
                                                            options:
                                                              _vm.allDepartements,
                                                            label: "message",
                                                            reduce: (option) =>
                                                              option.value,
                                                          },
                                                          on: {
                                                            "option:selected":
                                                              _vm.handleSelectDepartement,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.department,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.department =
                                                                $$v
                                                            },
                                                            expression:
                                                              "department",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm.selectedUser.length > 0
                                      ? _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              { attrs: { cols: "2" } },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "d-flex" },
                                                  [
                                                    _c("h6", [
                                                      _c("b", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              612,
                                                              "Person list"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "w-10 ml-2",
                                                        staticStyle: {
                                                          "margin-top": "-1px",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-badge",
                                                          {
                                                            staticClass:
                                                              "badge-variant",
                                                            attrs: { pill: "" },
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "badge-notification",
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "0.785rem",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .selectedUser
                                                                      .length
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              { attrs: { cols: "8" } },
                                              [
                                                _c("div", {
                                                  staticClass: "divider-dotted",
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass:
                                                  "d-flex justify-content-end",
                                                attrs: { cols: "2" },
                                              },
                                              [
                                                _c(
                                                  "h6",
                                                  {
                                                    staticClass:
                                                      "d-flex align-items-center cursor-pointer",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.clearAllSelectedUser()
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        _vm.ICONS.X_CLEAR.name
                                                      ),
                                                      {
                                                        tag: "component",
                                                        staticClass: "mr-1",
                                                        attrs: {
                                                          color:
                                                            _vm.ICONS.X_CLEAR
                                                              .color,
                                                          size: 18,
                                                          "stroke-width": 3,
                                                        },
                                                      }
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "margin-top": "2px",
                                                        },
                                                      },
                                                      [
                                                        _c("b", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                189,
                                                                "Clear All"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.selectedUser.length > 0
                                      ? _c(
                                          "b-row",
                                          {
                                            staticClass: "px-3 mt-2",
                                            class: `${
                                              _vm.selectedUser.length > 0
                                                ? "mb-2"
                                                : ""
                                            }`,
                                          },
                                          _vm._l(
                                            _vm.selectedUser,
                                            function (user) {
                                              return _c(
                                                "b-col",
                                                {
                                                  key: user.value,
                                                  staticClass: "d-flex mb-2",
                                                  attrs: { cols: "2" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-inline-flex align-items-center",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.removeUser(
                                                            user.value
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          _vm.ICONS.TRASH.name
                                                        ),
                                                        {
                                                          tag: "component",
                                                          staticClass:
                                                            "mr-2 cursor-pointer",
                                                          attrs: {
                                                            color:
                                                              _vm.ICONS.TRASH
                                                                .color,
                                                            size: 18,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.removeUser(
                                                                user.value
                                                              )
                                                            },
                                                          },
                                                        }
                                                      ),
                                                      _c(
                                                        "b-tooltip",
                                                        {
                                                          attrs: {
                                                            target: `tooltip-del-${user.value}`,
                                                            triggers: "hover",
                                                            placement: "left",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                47,
                                                                "Delete"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "b-tooltip",
                                                              rawName:
                                                                "v-b-tooltip.hover.top",
                                                              modifiers: {
                                                                hover: true,
                                                                top: true,
                                                              },
                                                            },
                                                          ],
                                                          staticClass:
                                                            "text-truncate",
                                                          staticStyle: {
                                                            "max-width":
                                                              "140px",
                                                            "margin-top": "2px",
                                                          },
                                                          attrs: {
                                                            title: user.message,
                                                          },
                                                        },
                                                        [
                                                          _c("b", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  user.message
                                                                )
                                                            ),
                                                          ]),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text-truncate",
                                                              staticStyle: {
                                                                "font-size":
                                                                  "0.85rem",
                                                                color:
                                                                  "rgba(6, 38, 62, 0.64)",
                                                                "margin-top":
                                                                  "-1px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    user.departmentName
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _c(
                        "fieldset",
                        {
                          staticClass:
                            "scheduler-border border-color-blue-streak",
                        },
                        [
                          _c(
                            "legend",
                            {
                              staticClass:
                                "scheduler-border text-color-blue-streak fs-16",
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(169, "Work details")) +
                                  " "
                              ),
                              !_vm.multipleSelectionDate && !_vm.useViewPlanning
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(_vm._f("frDate")(_vm.curDay.date))
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "b-col",
                            { staticClass: "pt-4", attrs: { xl: "12" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(23, "Type:"),
                                    "label-for": "dayType",
                                  },
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: {
                                      options: _vm.optionsForDefaultDayType,
                                      size: "md",
                                    },
                                    model: {
                                      value: _vm.curDay.dayType,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.curDay, "dayType", $$v)
                                      },
                                      expression: "curDay.dayType",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-collapse",
                                {
                                  attrs: { visible: !_vm.curDay.stageDayUsed },
                                },
                                [
                                  !_vm.curDay.stageDayUsed
                                    ? _c(
                                        "div",
                                        { staticClass: "mt-4" },
                                        [
                                          _c(
                                            "b-form-checkbox",
                                            {
                                              staticClass: "pj-cb pb-1",
                                              attrs: {
                                                id: "hours_not_specified",
                                                size: "lg",
                                                disabled: _vm.disableSave,
                                              },
                                              model: {
                                                value:
                                                  _vm.curDay.strEndNotSpecified,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.curDay,
                                                    "strEndNotSpecified",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "curDay.strEndNotSpecified",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      124,
                                                      "Hours not specified"
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "b-collapse",
                                {
                                  attrs: {
                                    visible: !_vm.curDay.strEndNotSpecified,
                                  },
                                },
                                [
                                  !_vm.curDay.strEndNotSpecified
                                    ? _c("div", [
                                        _vm.showStageDay()
                                          ? _c(
                                              "div",
                                              { staticClass: "mt-4" },
                                              [
                                                _c(
                                                  "b-form-checkbox",
                                                  {
                                                    staticClass: "pj-cb pb-1",
                                                    attrs: {
                                                      id: "stageDayUsed",
                                                      size: "lg",
                                                      disabled:
                                                        _vm.disableSave ||
                                                        !_vm.hasAvailableStageDay,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.curDay.stageDayUsed,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.curDay,
                                                          "stageDayUsed",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "curDay.stageDayUsed",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            24,
                                                            "Use Stage Day"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  !_vm.curDay.strEndNotSpecified
                                    ? _c("div", [
                                        _vm.showStageDay() &&
                                        !_vm.curDay.stageDayUsed
                                          ? _c("div", { staticClass: "my-2" }, [
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(_vm.FormMSG(25, "Or"))
                                                ),
                                              ]),
                                            ])
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  !_vm.curDay.strEndNotSpecified
                                    ? _c(
                                        "div",
                                        [
                                          !_vm.curDay.stageDayUsed
                                            ? _c(
                                                "b-row",
                                                { staticClass: "pb-2" },
                                                [
                                                  _c(
                                                    "b-col",
                                                    {
                                                      attrs: {
                                                        md: _vm.useFormInModal
                                                          ? 12
                                                          : 8,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "StartEndTimeOverRange",
                                                        {
                                                          attrs: {
                                                            "start-backward-day": 1,
                                                            "start-forward-day": 0,
                                                            "end-backward-day": 0,
                                                            "end-forward-day": 1,
                                                            value: {
                                                              start:
                                                                _vm.curDay
                                                                  .strTime,
                                                              end: _vm.curDay
                                                                .endTime,
                                                              date: _vm.curDay
                                                                .date,
                                                            },
                                                            "disable-save":
                                                              _vm.disableSave,
                                                            "hide-label-date":
                                                              _vm.days.length >
                                                              1,
                                                            multipleSelectionDate:
                                                              _vm.multipleSelectionDate,
                                                            days: _vm.days,
                                                            dataOneSelect:
                                                              _vm.listUserWithDates,
                                                          },
                                                          on: {
                                                            "change-range":
                                                              _vm.updateStrEndTimes,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _vm.showLunch() && !_vm.curDay.stageDayUsed
                                ? _c("div", {
                                    staticClass: "pt-3 divider-line",
                                  })
                                : _vm._e(),
                              _vm.showLunch()
                                ? _c(
                                    "div",
                                    { staticClass: "pt-3 lh-20" },
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          staticClass: "pj-cb pb-1",
                                          attrs: {
                                            id: "lunchNotSpec",
                                            size: "lg",
                                            disabled: _vm.disableSave,
                                          },
                                          model: {
                                            value: _vm.curDay.notSpecifiedLunch,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.curDay,
                                                "notSpecifiedLunch",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "curDay.notSpecifiedLunch",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  280,
                                                  "Not specified"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.curDay.stageDayUsed &&
                              _vm.showLunch() &&
                              !_vm.curDay.notSpecifiedLunch
                                ? _c("div", { staticClass: "my-2" }, [
                                    _c("b", [
                                      _vm._v(_vm._s(_vm.FormMSG(25, "Or"))),
                                    ]),
                                  ])
                                : _vm._e(),
                              !_vm.curDay.stageDayUsed &&
                              _vm.showLunch() &&
                              !_vm.curDay.notSpecifiedLunch
                                ? _c(
                                    "b-row",
                                    { staticClass: "pb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            md: _vm.useFormInModal ? 12 : 8,
                                          },
                                        },
                                        [
                                          _c("StartEndTimeOverRange", {
                                            attrs: {
                                              "start-backward-day": 1,
                                              "start-forward-day": 1,
                                              "end-backward-day": 1,
                                              "end-forward-day": 2,
                                              value: {
                                                start: _vm.curDay.lunchStrTime,
                                                end: _vm.curDay.lunchEndTime,
                                                date: _vm.curDay.date,
                                              },
                                              "disable-save": _vm.disableSave,
                                              "start-label": _vm.FormMSG(
                                                29,
                                                "Lunch Start:"
                                              ),
                                              "end-label": _vm.FormMSG(
                                                30,
                                                "Lunch End:"
                                              ),
                                              "hide-label-date":
                                                _vm.days.length > 1,
                                              multipleSelectionDate:
                                                _vm.multipleSelectionDate,
                                              days: _vm.days,
                                              dataOneSelect:
                                                _vm.listUserWithDates,
                                            },
                                            on: {
                                              "change-range":
                                                _vm.updateLunchTimes,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("div", { staticClass: "pt-3 divider-line" }),
                              _c(
                                "b-row",
                                { staticClass: "pt-3 lh-20" },
                                [
                                  _vm.showLunchPerDiem()
                                    ? _c(
                                        "b-col",
                                        [
                                          _c(
                                            "b-form-checkbox",
                                            {
                                              staticClass: "pj-cb pb-1",
                                              attrs: {
                                                id: "lunch",
                                                size: "lg",
                                                disabled: _vm.disableSave,
                                              },
                                              model: {
                                                value: _vm.curDay.lunch,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.curDay,
                                                    "lunch",
                                                    $$v
                                                  )
                                                },
                                                expression: "curDay.lunch",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      28,
                                                      "Lunch Per Diem"
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.showDinnerPerDiem()
                                    ? _c(
                                        "b-col",
                                        [
                                          _c(
                                            "b-form-checkbox",
                                            {
                                              staticClass: "pj-cb pb-1",
                                              attrs: {
                                                id: "dinner",
                                                size: "lg",
                                                disabled: _vm.disableSave,
                                              },
                                              model: {
                                                value: _vm.curDay.useDinner,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.curDay,
                                                    "useDinner",
                                                    $$v
                                                  )
                                                },
                                                expression: "curDay.useDinner",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      281,
                                                      "Dinner Per Diem"
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.showHotelPerDiem()
                                    ? _c(
                                        "b-col",
                                        [
                                          _c(
                                            "b-form-checkbox",
                                            {
                                              staticClass: "pj-cb pb-1",
                                              attrs: {
                                                id: "hotel",
                                                size: "lg",
                                                disabled: _vm.disableSave,
                                              },
                                              model: {
                                                value: _vm.curDay.hotel,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.curDay,
                                                    "hotel",
                                                    $$v
                                                  )
                                                },
                                                expression: "curDay.hotel",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      31,
                                                      "Hotel Per Diem"
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.showAbroadPerDiem()
                                    ? _c(
                                        "b-col",
                                        [
                                          _c(
                                            "b-form-checkbox",
                                            {
                                              staticClass: "pj-cb pb-1",
                                              attrs: {
                                                id: "abroad",
                                                size: "lg",
                                                disabled: _vm.disableSave,
                                              },
                                              model: {
                                                value: _vm.curDay.useAbroad,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.curDay,
                                                    "useAbroad",
                                                    $$v
                                                  )
                                                },
                                                expression: "curDay.useAbroad",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      282,
                                                      "Abroad Per Diem"
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "pt-3 divider-line" }),
                              _vm.showTransport()
                                ? _c(
                                    "div",
                                    { staticClass: "pt-3" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "lh-20" },
                                        [
                                          _c(
                                            "b-form-checkbox",
                                            {
                                              staticClass: "pj-cb pb-1",
                                              attrs: {
                                                id: "specifyTransport",
                                                size: "lg",
                                                disabled: _vm.disableSave,
                                              },
                                              model: {
                                                value:
                                                  _vm.curDay
                                                    .transportFromHomeToWork,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.curDay,
                                                    "transportFromHomeToWork",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "curDay.transportFromHomeToWork",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      224,
                                                      "Specify transport time from home to work"
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-collapse",
                                        {
                                          attrs: {
                                            visible:
                                              _vm.curDay
                                                .transportFromHomeToWork,
                                          },
                                        },
                                        [
                                          _c(
                                            "h3",
                                            { staticClass: "mt-3 mb-3" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    139,
                                                    "Transport from/to home"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                {
                                                  attrs: {
                                                    md: _vm.useFormInModal
                                                      ? 12
                                                      : 8,
                                                  },
                                                },
                                                [
                                                  _c("StartEndTimeOverRange", {
                                                    attrs: {
                                                      "start-backward-day": 1,
                                                      "start-forward-day": 0,
                                                      "end-backward-day": 0,
                                                      "end-forward-day": 2,
                                                      value: {
                                                        start:
                                                          _vm.curDay.leftHomeAt,
                                                        end: _vm.curDay
                                                          .returnedHomeAt,
                                                        date: _vm.curDay.date,
                                                      },
                                                      "disable-save":
                                                        _vm.disableSave,
                                                      "hide-label-date":
                                                        _vm.days.length > 1,
                                                      "start-label":
                                                        _vm.FormMSG(
                                                          126,
                                                          "Left home at:"
                                                        ),
                                                      "end-label": _vm.FormMSG(
                                                        127,
                                                        "Returned home at:"
                                                      ),
                                                      days: _vm.days,
                                                      multipleSelectionDate:
                                                        _vm.multipleSelectionDate,
                                                    },
                                                    on: {
                                                      "change-range":
                                                        _vm.updateLeftReturnedTimes,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("b-col", [
                            _c("div", { staticClass: "pt-3 divider-line" }),
                            _c(
                              "div",
                              { staticClass: "lh-20" },
                              [
                                _c(
                                  "b-row",
                                  { staticClass: "pt-3" },
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "3" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: _vm.FormMSG(
                                                587,
                                                "Working site"
                                              ),
                                            },
                                          },
                                          [
                                            !_vm.showOtherLocation
                                              ? _c("b-form-select", {
                                                  attrs: {
                                                    label: "setName",
                                                    "text-field": "setName",
                                                    "value-field": "id",
                                                    options:
                                                      _vm.projectLocationsList,
                                                    placeholder: _vm.FormMSG(
                                                      594,
                                                      "Please select"
                                                    ),
                                                    reduce: (opt) => opt.id,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "first",
                                                        fn: function () {
                                                          return [
                                                            _c(
                                                              "b-form-select-option",
                                                              {
                                                                attrs: {
                                                                  value: null,
                                                                  disabled: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      594,
                                                                      "Please select"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    4027304841
                                                  ),
                                                  model: {
                                                    value:
                                                      _vm.curDay.locationId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.curDay,
                                                        "locationId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "curDay.locationId",
                                                  },
                                                })
                                              : _c("b-form-input", {
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      541,
                                                      "Other"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.showOtherLocation
                                      ? _c(
                                          "b-col",
                                          {
                                            staticClass: "pt-28",
                                            attrs: { cols: "3" },
                                          },
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                placeholder: _vm.FormMSG(
                                                  521,
                                                  "Please specify"
                                                ),
                                              },
                                              model: {
                                                value: _vm.otherLocation,
                                                callback: function ($$v) {
                                                  _vm.otherLocation = $$v
                                                },
                                                expression: "otherLocation",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("b-col", [
                            _c("div", { staticClass: "pt-2 divider-line" }),
                            _c(
                              "div",
                              { staticClass: "pt-3 lh-20" },
                              [
                                _c(
                                  "b-form-checkbox",
                                  {
                                    staticClass: "pj-cb pb-1",
                                    attrs: {
                                      id: "chkMileageToSet",
                                      size: "lg",
                                    },
                                    model: {
                                      value: _vm.curDay.useMileageToSet,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.curDay,
                                          "useMileageToSet",
                                          $$v
                                        )
                                      },
                                      expression: "curDay.useMileageToSet",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.FormMSG(265, "Mileage to set")
                                        ) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm.curDay.comment.length
                            ? _c(
                                "b-row",
                                { staticClass: "w-100" },
                                [
                                  _c("div", {
                                    staticClass: "pt-3 divider-line",
                                  }),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "px-3 ml-3",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: _vm.FormMSG(32, "Comment:"),
                                            "label-for": "comment",
                                          },
                                        },
                                        [
                                          _c("b-form-textarea", {
                                            attrs: { id: "comment", rows: "3" },
                                            model: {
                                              value: _vm.curDay.comment,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.curDay,
                                                  "comment",
                                                  $$v
                                                )
                                              },
                                              expression: "curDay.comment",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "b-row",
                            { staticClass: "w-100" },
                            [
                              _c("div", { staticClass: "pt-3 divider-line" }),
                              _c(
                                "b-col",
                                {
                                  staticClass: "px-3 ml-3",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: _vm.FormMSG(32, "Comment:"),
                                        "label-for": "commentss",
                                      },
                                    },
                                    [
                                      _c("b-form-textarea", {
                                        attrs: {
                                          disabled: _vm.disableSave,
                                          id: "commentss",
                                          rows: "3",
                                        },
                                        model: {
                                          value: _vm.curDay.remark,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.curDay, "remark", $$v)
                                          },
                                          expression: "curDay.remark",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "pt-2 divider-line" }),
                        ],
                        1
                      ),
                      !_vm.hideSaveBtn
                        ? _c(
                            "div",
                            { staticClass: "d-flex justify-content-end" },
                            [
                              (_vm.curDay.isAvailable &&
                                !_vm.allowTimesheetEntryOutOfContract) ||
                              _vm.allowTimesheetEntryOutOfContract
                                ? _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass:
                                            "save-mobile-floating-button clearfix",
                                          attrs: { sm: "12" },
                                        },
                                        [
                                          !(
                                            _vm.disableSaveButton ||
                                            _vm.disableSave ||
                                            _vm.dateDisabled() === true
                                          )
                                            ? _c(
                                                "b-button",
                                                {
                                                  staticClass:
                                                    "d-flex justify-content-center align-items-center w-100 px-4",
                                                  attrs: {
                                                    variant: "outline-primary",
                                                    size: "md",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.saveCurDay()
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm.newDaySelected
                                                    ? _c(
                                                        _vm.getLucideIcon(
                                                          "CalendarPlus"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          staticClass: "mr-2",
                                                          attrs: { size: 20 },
                                                        }
                                                      )
                                                    : _c(
                                                        _vm.getLucideIcon(
                                                          "CalendarCheck2"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          staticClass: "mr-2",
                                                          attrs: { size: 20 },
                                                        }
                                                      ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "margin-top": "1px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.buttonLabel()
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-modal",
                        {
                          staticClass: "modal-success",
                          attrs: {
                            "header-class": "header-class-modal-doc-package",
                            title: _vm.FormMSG(34, "Success!"),
                            "ok-variant": "success",
                            "ok-only": "",
                          },
                          on: {
                            ok: function ($event) {
                              _vm.successModal = false
                            },
                          },
                          model: {
                            value: _vm.successModal,
                            callback: function ($$v) {
                              _vm.successModal = $$v
                            },
                            expression: "successModal",
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(
                                _vm.FormMSG(
                                  35,
                                  "Your information has been saved. Don't forget to send your timesheets for validation with the form below."
                                )
                              ) +
                              "\n\t\t\t\t"
                          ),
                        ]
                      ),
                      _c(
                        "b-modal",
                        {
                          staticClass: "modal-success",
                          attrs: {
                            "header-class": "header-class-modal-doc-package",
                            title: _vm.FormMSG(34, "Success!"),
                            "ok-variant": "success",
                            "ok-only": "",
                          },
                          on: {
                            ok: function ($event) {
                              _vm.successModalDelete = false
                            },
                          },
                          model: {
                            value: _vm.successModalDelete,
                            callback: function ($$v) {
                              _vm.successModalDelete = $$v
                            },
                            expression: "successModalDelete",
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(
                                _vm.FormMSG(
                                  36,
                                  "Your information has been deleted."
                                )
                              ) +
                              "\n\t\t\t\t"
                          ),
                        ]
                      ),
                      _c(
                        "b-modal",
                        {
                          attrs: {
                            "hide-footer": "",
                            "no-close-on-backdrop": "",
                            "no-close-on-esc": "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "modal-title",
                                fn: function () {
                                  return [
                                    _c(
                                      "span",
                                      { staticClass: "w-94" },
                                      [
                                        _c(
                                          _vm.getLucideIcon(
                                            _vm.ICONS.ALERT_TRIANGLE.name
                                          ),
                                          {
                                            tag: "component",
                                            attrs: {
                                              color:
                                                _vm.ICONS.ALERT_TRIANGLE.color,
                                              size: 18,
                                            },
                                          }
                                        ),
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t " +
                                            _vm._s(
                                              _vm.FormMSG(
                                                37,
                                                "No valid contract on this day"
                                              )
                                            ) +
                                            "\n\t\t\t\t\t\t"
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            568464244
                          ),
                          model: {
                            value: _vm.showPopupInfo,
                            callback: function ($$v) {
                              _vm.showPopupInfo = $$v
                            },
                            expression: "showPopupInfo",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-direction": "column",
                                gap: "5px",
                              },
                            },
                            _vm._l(_vm.dataToPopupInfo, function (item) {
                              return _c(
                                "div",
                                {
                                  key: _vm.generateSecureId(
                                    `${item.id + Math.random() * 1000000}`
                                  ),
                                  staticStyle: {
                                    border: "2px solid #cad0d7",
                                    padding: "10px",
                                    "border-radius": "5px",
                                    gap: "10px",
                                  },
                                },
                                [
                                  _c("div", [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(_vm.FormMSG(38, "User name:")) +
                                          " " +
                                          _vm._s(item.user.name) +
                                          " " +
                                          _vm._s(item.user.firstName)
                                      ),
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(_vm.FormMSG(39, "Date:")) +
                                          " " +
                                          _vm._s(_vm.reverseDate(item.date))
                                      ),
                                    ]),
                                  ]),
                                ]
                              )
                            }),
                            0
                          ),
                          [
                            _c(
                              "div",
                              { staticClass: "w-100 float-right" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "mt-3 float-right",
                                    attrs: {
                                      variant: "outline-primary",
                                      size: "sm",
                                    },
                                    on: { click: _vm.closePopup },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(_vm.FormMSG(40, "Close")) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            "header-class": "header-class-modal-doc-package",
            title: _vm.FormMSG(699, "Out of contract date"),
            "ok-only": "",
            "ok-variant": "outline-primary",
            "ok-title": _vm.FormMSG(700, "Ok"),
            "hide-header-close": "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": "",
          },
          model: {
            value: _vm.showWarningOutOfContractDate,
            callback: function ($$v) {
              _vm.showWarningOutOfContractDate = $$v
            },
            expression: "showWarningOutOfContractDate",
          },
        },
        [
          _c("div", { staticClass: "alert alert-warning" }, [
            _vm._v(
              _vm._s(
                _vm.FormMSG(
                  701,
                  `You don't have any contract valid on this selected date.`
                )
              )
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }