var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "d-flex" }, [
        _c("div", { staticStyle: { width: "9%" } }, [
          _c(
            "button",
            {
              staticClass: "float-left",
              staticStyle: {
                "background-color": "transparent",
                border: "none",
              },
              on: { click: _vm.handleClickPrevDate },
            },
            [
              _c("b-icon", {
                staticStyle: { "margin-top": "6px" },
                attrs: { icon: "chevron-left", "font-scale": "1.5" },
              }),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "text-center", staticStyle: { width: "82%" } },
          [
            _c("span", { staticClass: "label-calendar" }, [
              _vm._v(_vm._s(_vm.currDateStr)),
            ]),
          ]
        ),
        _c("div", { staticStyle: { width: "9%" } }, [
          _c(
            "button",
            {
              staticClass: "float-right",
              staticStyle: {
                "background-color": "transparent",
                border: "none",
              },
              on: { click: _vm.handleClickNextDate },
            },
            [
              _c("b-icon", {
                staticStyle: { "margin-top": "6px" },
                attrs: { icon: "chevron-right", "font-scale": "1.5" },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm.showStatus && !_vm.isNew
        ? _c(
            "b-row",
            { staticClass: "mt-1" },
            [
              _c("b-col", [
                !_vm.isFilmSingle
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "wrap-status d-flex justify-content-center",
                      },
                      [
                        _c(
                          "div",
                          {
                            class: `status ${_vm.curDay.statusClass}`,
                            staticStyle: { "font-size": "0.9rem" },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(_vm.curDay.validatedStatus) +
                                "\n\t\t\t\t"
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }